import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgHero from "../images/gfx/hero-packages-shuttleid-scan-ticket.jpg"
import imgMobileHero from "../images/gfx/mobile-packages-hero.jpg"
import imgProfileKevinMortlock from "../images/profile-kevin-mortlock-circle.png"
import imgProfileGrahamBethell from "../images/profile-graham-bethell-circle.png"
import imgLiveTracking from "../images/gfx/school-bus-tracking-system.jpg"
import imgLiveScanning from "../images/gfx/school-bus-tracking-app.jpg"
import imgPassengerScan from "../images/gfx/coach-scan-ticket-side.jpg"
import imgCaseStudyKeane from "../images/keane-travel-case-study-small.jpg"
import "./index.css";
import "./case-study.css";
import "./dte-offer.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Exclusive offer for Don't Travel Empty members" />
        <Nav activeTab="packages" />
        <div>
          <div>

            <div className='packages'>
              <div className="hero" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '60% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1>FREE ticket scanners for DTE members</h1>
                <h2>Offer ends June 14th. Contact us today to claim.</h2>
              </div>

              <div className="mobile-hero" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1>FREE ticket scanners for DTE members</h1>
                <h2>Offer ends June 14th. Contact us today to claim.</h2>
              </div>
            </div>
{/*
              <div className="hero-standard" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1>FREE ticket scanners for DTE members</h1>
                <h2>Offer ends June 14th. Contact us today to claim.</h2>
                <div className="hero__links">
                  <Link to="/contact/" className="btn">Contact</Link>
                </div>
              </div>

              <div className="mobile-hero-standard" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1>FREE ticket scanners for DTE members</h1>
                <h2>Offer ends June 14th. Contact us today to claim.</h2>
                <div className="hero__links">
                  <Link to="/contact/" className="btn">Contact</Link>
                </div>
              </div> */}

              <div className="contact-page">
                <div className="dte article" style={{ maxWidth: 900 }}>



                  <div className='article-inner'>

                    <div className="contact__section">
                      {/* <div style={{ padding: 20, background: '#fd2727', marginBottom: 30 }}>
                        <h2 style={{ color: 'white' }}>FREE Live vehicle tracking for 12 months</h2>
                        <p style={{ color: 'white', margin: 0 }}>ShuttleID has partnered with Don't Travel Empty to give its members an exclusive offer throughout the whole month of June; Up to five <strong>FREE ticket scanners</strong> when you sign up to one of our <Link style={{ color: 'white' }} to='/packages/'>packages</Link>. Terms and conditions apply.</p>
                      </div> */}
                      <p style={{ fontSize: '1.3em', lineHeight: '150%' }}>ShuttleID has partnered with Don't Travel Empty again to give DTE members an exclusive offer until June 14th; Up to five <strong>FREE ticket scanners</strong> when you sign up to one of our <Link to='/packages/'>packages</Link>.</p>
                      <p style={{ fontSize: '1.3em', lineHeight: '150%' }}>This offer was last available to DTE members two years ago - once it's gone, it's gone! <Link to="/contact/">Contact us</Link> today to avoid missing out on this great deal.</p>
                      <div className="case-study-promo">
                        <p className="case-study-promo__img"><Link to='/case-study-keane-travel-reduce-admin-increase-revenue-school-travel/'><img src={imgCaseStudyKeane} alt="Keane Travel using ShuttleID" /></Link></p>
                        <div className="case-study-promo__text">
                          <Link className="case-study-promo__text__body" to="/case-study-keane-travel-reduce-admin-increase-revenue-school-travel/">How one DTE member halved admin, increased revenue 50% and reduce failed payments by 90% on their school services.</Link>
                          <Link to='/case-study-keane-travel-reduce-admin-increase-revenue-school-travel/' className='btn'>
                            View case study <i className="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>

                      <h2>About ShuttleID</h2>
                      <p>ShuttleID is an affordable, cashless bus pass and ticketing system that makes home-to-school transport simple to manage.</p>
                      <p>We use consumer grade hardware so simple ticket issuing and scanning becomes affordable on your school services, without the hassle of manually managing payments and physical passes.</p>

                      <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileGrahamBethell} alt="Graham Bethell" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                      <p className='case-study__profile-quote__author' style={{ marginBottom: 20 }}>Graham Bethell, DTE member, South Staffs Coach Hire</p>
                      <p>"Having just set up ShuttleID I can honestly say it's a brilliant cost effective system. Ideal for coach operators wanting a home to school ticket system without the huge expense of hardware, but still gives better control of ticket issuing and usage."</p>
                      <p>"Over the weekend we have sold 30 tickets on one service without any input from ourselves. That was a weeks work last year!"</p>

                      </div>
                    </div>

                    <h3>How it works</h3>
                    <ul>
                      <li>You list tickets for sale (Complete package) or create/import passengers (Simple package)</li>
                      <li>Parents buy using card or direct debit (Complete package only)</li>
                      <li>Ticket is immediately generated and sent to customer's phone or printed</li>
                      <li>Passenger self scans ticket when boarding</li>
                      <li>Driver only intervenes when issue is flagged</li>
                      <li>Your operations team get full visibility of scan activity to follow up incidents</li>
                      <li>Ops portal makes management simple: SMS service alerts, void tickets, payment logs, move passengers between services &amp; much more</li>
                      <li>Live vehicle tracking for customers accessed directly from their ticket (LIVE add-on only)</li>
                    </ul>
                    <p>Further information is available on our <Link to='/'>homepage</Link>.</p>

                    <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileKevinMortlock} alt="Kevin Mortlock" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                        <p className='case-study__profile-quote__author' style={{ marginBottom: 20 }}>Kevin Mortlock, DTE member, Keane Travel</p>
                        <p>"In 3 days since going live we have sold 75 tickets with very little input from ourselves. Customers have commented how quick and easy it is to buy tickets. Chris at ShuttleID has been very helpful and has made the transition very easy for us."</p>
                        <p>"The most important thing about this system is that our back office work has already reduced, saving us valuable time. We won’t be going back to our old system, which I thought was pretty much up to date but now looks archaic!"</p>
                      </div>
                    </div>

                    <h2>How to claim your offer</h2>
                    <p>To find out more information contact us:</p>
                    <ul>
                      <li>Complete our <Link to="/contact/">contact form</Link></li>
                      <li>Email <a href="mailto:info@shuttleid.uk">info@shuttleid.uk</a></li>
                      <li>Call 0333 344 9868</li>
                    </ul>
                    <p>When you speak with one of our friendly sales team, just mention you're a DTE member and we'll apply the offer. Offer ends 14th June 2022. Terms and conditions apply.</p>
                    </div>

                  </div>
                </div>
              </div>
              <Contact/>

              {/* <div style={{ display: 'flex', backgroundImage: `url(${imgCoach})`, backgroundColor: '#fbfbfb', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>

                <div className="blog" style={{ marginTop: 100, marginBottom: 100 }}>
                  <div className='headline-block blog-inner' style={{ paddingTop: '3em' }}>
                    <h1 className="blog-headline" style={{ textAlign: 'left' }}>FREE ticket scanners for DTE members</h1>
                    <img src={imgDteOffer} alt="" />
                    <p style={{ fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>ShuttleID has partnered with Don't Travel Empty to give its members an exclusive offer throughout the whole month of June; Up to five <strong>FREE ticket scanners</strong> when you sign up to one of our <Link to='/packages/'>packages</Link>.</p>
                  </div>
                  <div style={{ marginTop: '50px' }} className='blog-inner'>
                    <h2>About ShuttleID</h2>
                    <p>ShuttleID is a cost-effective cashless bus pass and ticketing system that makes it easy to manage home to school transport.</p>
                    <p>We use consumer grade hardware so simple ticket issuing and scanning becomes affordable on your school services, without the hassle of manually managing payments and physical passes.</p>

                    <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileGrahamBethell} alt="Graham Bethell" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                      <p className='case-study__profile-quote__author' style={{ marginBottom: 20 }}>Graham Bethell, DTE member, South Staffs Coach Hire</p>
                      <p><strong>"Having just set up ShuttleID I can honestly say it's a brilliant cost effective system. Ideal for coach operators wanting a home to school ticket system without the huge expense of hardware, but still gives better control of ticket issuing and usage."</strong></p>
                      <p><strong>"Over the weekend we have sold 30 tickets on one service without any input from ourselves. That was a weeks work last year!"</strong></p>

                      </div>
                    </div>

                    <h3>How it works</h3>
                    <ul>
                      <li>You list tickets for sale (Complete package) or create/import passengers (Simple package)</li>
                      <li>Parents buy using card or direct debit (Complete package only)</li>
                      <li>Ticket is immediately generated and sent to customer's phone or printed</li>
                      <li>Passenger self scans ticket when boarding</li>
                      <li>Driver only intervenes when issue is flagged</li>
                      <li>Your operations team get full visibility of scan activity to follow up incidents</li>
                      <li>Ops portal makes management simple: SMS service alerts, void tickets, payment logs, move passengers between services &amp; much more</li>
                    </ul>
                    <p>Further information is available on our <Link to='/'>homepage</Link>.</p>

                    <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileKevinMortlock} alt="Kevin Mortlock" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                        <p className='case-study__profile-quote__author' style={{ marginBottom: 20 }}>Kevin Mortlock, DTE member, Keane Travel</p>
                        <p><strong>"In 3 days since going live we have sold 75 tickets with very little input from ourselves. Customers have commented how quick and easy it is to buy tickets. Chris at ShuttleID has been very helpful and has made the transition very easy for us."</strong></p>
                        <p><strong>"The most important thing about this system is that our back office work has already reduced, saving us valuable time. We won’t be going back to our old system, which I thought was pretty much up to date but now looks archaic!"</strong></p>
                      </div>
                    </div>

                    <h2>How to claim your offer</h2>
                    <p>To find out more information contact us:</p>
                    <ul>
                      <li>Email <a href="mailto:info@shuttleid.uk">info@shuttleid.uk</a></li>
                      <li>Call 0333 344 9868</li>
                      <li>Complete our <Link to="/pricing/">price request form</Link></li>
                    </ul>
                    <p>When you speak with one of our friendly sales team, just mention you're a DTE member and we'll apply the offer. Offer ends 14th June 2022.</p>
                  </div>
                </div>
              </div> */}
            </div>
        </div>
      </Layout>
    );
  }
};

export default BlogPage